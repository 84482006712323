import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import "../../../../assets/_sass/page/recruit/interview.scss";
import "../../../../assets/_sass/page/recruit/article01.scss";
import { useMobile } from "../../../../utils/use-series";
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "坂本誠也",
    content: "Seiya Sakamoto",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "big-word-time",
    cla_right: "",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "坂本誠也",
      src: "/recruit/interview/article01/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title: "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "大学では芸術学部アニメーション学科に在籍し、将来は作品の予算やスケジュールを管理する制作進行の仕事に就くつもりでした。監督や作画担当をはじめ関係者の意見をまとめる重要な役割であり、スキルを磨くべく講義や実習に励んでいました。",
      },
      {
        title:
          "そもそも昔から人と話すことが好きで、学生時代も制作スタッフとの関係をゼロから構築するのが得意でしたね。やがてコミュニケーションを主とする職業に関心を持ち、「道を極めるなら接客業だろう」との思いから現在の道に進んでいます。アニメ制作からホテルマンとは、なかなか珍しい進路の転換ですよね。",
      },
    ],
  };
  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article02.png",
      alt: "",
    },
    title: "当社を選ばれた理由は何ですか",
    paragraph: [
      {
        title:
          "就職活動も「接客」を軸に、旅館や飲食店も含め複数の企業を訪問しました。もちろん汐留エリアにある各ホテルも見学し、全体を通して最も魅力を感じたのが「ザ ロイヤルパークホテル アイコニック 東京汐留」でした。",
      },
      {
        title:
          "職場見学で初めて訪れた時の感動は今でも覚えています。エレベーターの扉がロビー階で開くと、明るく開放的な大人の空間が広がり、放心している私にベルスタッフが笑顔で声をかけてくれました。そして案内されたソファに座りながらスタッフの動きに見とれるうちに、その「洗練」「自然体」「温かみ」が調和した姿に感動して入社を強く希望するようになりました。",
      },
    ],
    subcontent:
      "また、複数のレストラン、スパをはじめ、他の宿泊主体型ホテルでは考えられないほど館内施設が充実していた点にも惹かれましたね。さらに「三菱地所グループ」として福利厚生が整っていることも入社を後押ししました。中でも重宝しているのが「団体活動費」で、複数のスタッフが社外で親睦を深める際に使ったお金を補助してくれる制度です。以前、同期とラフティングに出かけた際に利用しましたが「さすがはメンバー間の意思疎通や連携を大事にする会社だな」と感じています。",
    // position: true,
    positionSp: true,
  };

  const picData2 = {
    img: {
      src: "/assets/images/recruit/interview/article03.png",
      alt: "",
    },
    title: "現在の業務内容や仕事の醍醐味を教えてください",
    paragraph: [
      {
        title:
          "フロントスタッフとして勤務し、時間帯責任者であるインチャージと呼ばれる立場にいます。総支配人や支配人の不在時に現場の全責任を負うポジションであり、任命された喜びとともにプレッシャーも感じています。",
      },
      {
        title:
          "実際に全490ある客室のチェックインやチェックアウトを行う中、難題が降りかかることもゼロではありません。万が一、サービスに不備があった場合はお客さまに直接会ってお詫びをし、名刺を渡して私なりの解決案を提示します。ご理解いただけるお客さまがほとんどで、中にはチェックアウト時に私を呼び、感謝の言葉やホテルへの賛辞を口にしてくださる方も多いです。まさにピンチはチャンスですよね。",
      },
      {
        title:
          "なおフロント業務では、特に予約や精算時にダブルブッキングや金額の誤入力が無いよう二重チェックを徹底しています。かつて私のミスが勤務時間外に発覚し、同僚が代わってお客さまに頭を下げてくれたことがあります。そんな、申し訳なく悔しい気持ちは誰にも味わってほしくありません。",
      },
      {
        title:
          "そんな思いもあり、特に後輩がお客さまに褒められている場面を見るのが醍醐味ですね。つい先日までは右往左往していたのに、同じような状況で人を喜ばせるまでに成長した姿を目の当たりにすると自分事のようにうれしくなります。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article04.png",
      alt: "",
    },
    title: "職場の雰囲気はいかがですか",
    paragraph: [
      {
        title:
          "私が勤務する「ザ ロイヤルパークホテル アイコニック 東京汐留」だけでなく「三菱地所ホテルズ＆リゾーツ」にはフレンドリーなスタッフが多いです。よく社員割引を利用して系列のホテルに宿泊しますが、チェックイン時に名前を伝えると現地のスタッフ達が出迎えてくれます。チェックアウトの際も見送ってくれ、互いの苦労話などで盛り上がりますね。",
      },
      {
        title:
          "汐留でもフロントスタッフとベルスタッフが業務の垣根を越えてフォローし合うのは日常茶飯事であり、さらに同じ時間に退社するメンバーが作業していたら手伝って一緒に上がろうとする風潮です。",
      },
      {
        title:
          "総支配人や支配人も時間が許せばロビーやレストランに顔を出してくれます。お客さまへの英語対応も非常にスマートで、常に堂々としてみんなの手本になっていますね。スタッフとの距離も近く職場自体も何でも話せる雰囲気で、私も最近はインボイス制度導入に伴う領収書発行方法の改善を提案したりしました。若手からも次々と面白いアイデアが出てきます。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article05.png",
      alt: "",
    },
    title: "当社に入り、どのような成長を感じていますか",
    paragraph: [
      {
        title:
          "まずはインチャージとしての能力が身に付いたことでしょう。室料および販売室数の設定、既存オペレーションの見直しなど多岐にわたるスキルが備わってきたと実感しています。お客さまの意見を基にゼロからオペレーションを構築していくことにも興味があるので、今後は新たなホテルの立ち上げにも携わりたいです。",
      },
      {
        title:
          "また、マネジメントの経験も積んでいますね。後輩には単に私の知見を伝えるだけでなく、自らの考えを持ってもらえるよう心がけて指導に臨んでいます。先日もドアマンの制度を復活させた際、若手からの意見で以前はセキュリティ上設置が難しかったノートPCをドアマンデスクに設置することができました。予約確認作業などがスムーズになりお客様をお待たせすることも少なくなってきています。私も総支配人や支配人のように「何でも言える存在」を目指しているので、少しでも近づけていたら光栄です。",
      },
    ],
    subcontent: "",
    position: true,
    positionSp: true,
  };
  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article06.png",
      alt: "",
    },
    title: "最も達成感を得られた出来事は何ですか",
    paragraph: [
      {
        title:
          "コロナ禍では「ザ ロイヤルパークホテル 東京羽田」に異動し勤務していました。当時は海外から帰国・入国された方がホテル内での14日間にわたる隔離を余儀なくされ、窮屈な思いをされているのが手に取るように分かりました。",
      },
      {
        title:
          "そこで私はスタッフと共に、クリスマスに合わせてカードを手作りし、お菓子と共に渡すことにしました。芸術学部出身なのでカードのデザインを引き受け、スタッフに手書きのメッセージを添えてもらいました。支配人もサンタクロースに扮して子どもたちを喜ばせるなど、心温まるひとときを提供できたと思います。",
      },
      {
        title:
          "おかげさまでこの活動は、社内に素晴らしい影響を与えた取り組みや個人が表彰される「人財グランプリ」において「ノミネート賞」に選ばれました。グランプリは毎年開催され、難関資格を取得した調理スタッフや業務効率化に貢献した経理担当者なども表彰されています。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };
  const interviewFullData2 = {
    title:
      "ホテルで働く魅力や、当社を目指す方々へのメッセージをお聞かせください",
    paragraph: [
      {
        title:
          "毎日いろいろなお客さまと接し、変化に富んだ日々を過ごせる点が何よりの魅力です。私たちのサービスに期待することも一人一人で異なるため、創意工夫が好きな方に向いている仕事だと思います。",
      },
      {
        title:
          "また、平日に休みを取れるのもホテルで働く大きなメリットです。有名なテーマパークにも人気シェフのレストランにも、話題の観光地にも混雑を避けて遊びにいけますね。",
      },
      {
        title:
          "加えて当ホテルは外国人のお客さまが非常に多く、全体の85％ほどを占めます。そのため英語のスキルが不可欠であるとともに、本物の会話力が身に付くのも利点でしょう。ありがたいことに社内には自己啓発支援制度もあり、英語以外にも自身の成長を促す仕組みが整っています。",
      },
    ],
  };
  const picData6 = {
    img: {
      src: "/assets/images/recruit/interview/article07.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "ホテル業界を志望する方々にも、まずは英語力に磨きをかけていただきたいですね。特に都内のホテルへの就職を目指すなら、早めに勉強を始めることをお勧めします。働き始めると他にも学ばなければならないことが多く、語学の習得に十分な時間を割けないこともあるので、ぜひ事前に準備しておいてください。",
      },
      {
        title:
          "さらに国内外のニュースに触れることも重要でしょう。ビジネス、世界情勢、スポーツをはじめ、お客さまが話題にされた時にどれだけ一緒に盛り上がれるかも接客業では必須のスキルだと思います。",
      },
      {
        title:
          "最後に、ホテルの仕事ではいつも感謝の気持ちを忘れないことが大切です。上辺だけの言葉や表情は簡単に見透かされ、逆にお客さまの印象を悪くしてしまう可能性もありますよね。だからこそ、心から「ありがとうございます」と言える方々と一緒に働けることを楽しみにしています。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />
                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article-media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}

                <div className="interview_title">
                  <p className="m_title_message m_title_message-media">
                    &nbsp;「人と関わる」を極めるため
                    <br className="u_pc" />
                    アニメ制
                    <br className="u_sp" />
                    作の道からホテルマンに軌道修正。
                    <br />
                    入社10年目を前に改めて
                    <br className="u_pc" />
                    「意思疎通や
                    <br className="u_sp" />
                    連携を大事にする会社」と実感
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message m_title_subtitle-media">
                    2015年 新卒入社
                    <br />ザ ロイヤルパークホテル アイコニック 東京汐留 <br className="u_sp" />フロントチーム
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass="wordfull-article-media container_main_message_full2"
              />

              <WordPic
                data={picData1}
                exClass="container_message_picture_same container_main_message_bisect2"
                exSubClass="container_main_message_bisect2 mt--5-sp"
              />
              <WordPic
                data={picData2}
                exClass=" container_main_message_bisect2"
                exRightClass="article01-p2-p"
                bisectRightClass="bisect_right_fullImg60"
              />

              <WordPic
                data={picData3}
                exClass="reverse-container_main_message_bisect container_main_message_bisect2"
                exRightClass="small-right-pic bisect_right2 "
              />

              <WordPic
                data={picData4}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article01-p4-p"
              />

              <WordPic
                data={picData5}
                exClass="reverse-container_main_message_bisect  container_main_message_bisect2"
                exRightClass="small-right-pic bisect_right2"
              />
              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData6}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article01-p7-p"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main u_grey">
              <div className="container_main container_main_time_a">
                <Vertical data={verticalDataTime} />
                {isSp ? (
                  <>
                    <div className="interview_a1_mythBox article01-mythBox">
                      <div className="interview_a1_timebox-left">
                        <p className="interview_a1_timebox-title">日勤</p>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              08:30
                              <div className="interview_a1_timeline-title">
                                出勤、引継ぎ <br />
                                チェックアウト業務
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time02.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              12:00
                              <div className="interview_a1_timeline-title">
                                午前中の会計確認
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time03.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              13:00
                              <div className="interview_a1_timeline-title">
                                休憩
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time04.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              14:00
                              <div className="interview_a1_timeline-title">
                                チェックイン業務
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time05.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              17:00
                              <div className="interview_a1_timeline-title">
                                引継ぎ、退勤
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="interview_a1_timebox-right">
                        <p className="interview_a1_timebox-title">夜勤</p>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time06.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              16:00
                              <div className="interview_a1_timeline-title">
                                出勤、引継ぎ <br />
                                チェックアウト業務
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time07.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              19:00
                              <div className="interview_a1_timeline-title">
                                食事休憩
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time08.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              20:00
                              <div className="interview_a1_timeline-title">
                                チェックイン業務
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time09.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              02:00~
                              <div className="interview_a1_timeline-title">
                                会計確認、一日の締め作業
                                <br />
                                仮眠 <br /> 当日到着ゲストの準備
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time10.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              07:00
                              <div className="interview_a1_timeline-title">
                                チェックアウト業務
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time11.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              09:00
                              <div className="interview_a1_timeline-title">
                                引継ぎ、退勤
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="interview_a1_mythBox article01-mythBox">
                      <div className="interview_a1_timebox-left">
                        <p className="interview_a1_timebox-title">日勤</p>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              08:30
                            </div>
                            <div className="interview_a1_timeline-title">
                              出勤、引継ぎ <br />
                              チェックアウト業務
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time02.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              12:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              午前中の会計確認
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time03.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              13:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              休憩
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time04.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              14:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              チェックイン業務
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time05.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              17:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              引継ぎ、退勤
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="interview_a1_timebox-right">
                        <p className="interview_a1_timebox-title">夜勤</p>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time06.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              16:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              出勤、引継ぎ <br />
                              チェックアウト業務
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time07.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              19:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              食事休憩
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time08.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              20:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              チェックイン業務
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time09.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              02:00~
                            </div>
                            <div className="interview_a1_timeline-title">
                              会計確認、一日の締め作業
                              <br />
                              仮眠 <br /> 当日到着ゲストの準備
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time10.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              07:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              チェックアウト業務
                            </div>
                          </div>
                        </div>
                        <div className="interview_a1_timeline-item">
                          <i className="interview_a1_timeline-axis">
                            <AssetImage
                              src="/assets/images/recruit/interview/time11.svg"
                              alt=""
                              loading="lazy"
                            />
                          </i>
                          <div className="interview_a1_timeline-content text">
                            <div className="interview_a1_timeline-detail">
                              09:00
                            </div>
                            <div className="interview_a1_timeline-title">
                              引継ぎ、退勤
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
